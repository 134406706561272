import {
  DxAccordionModule,
  DxBarGaugeModule,
  DxButtonModule,
  DxCalendarModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDateRangeBoxModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxNumberBoxModule,
  DxPieChartModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSliderModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextBoxModule,
  DxTreeListModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule,
  DxVectorMapModule
} from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SafePipe } from '@app/core/pipes/safe.pipe';
import { SharedGetFilesComponent } from '@app/shared/components/get-files/shared-get-files.component';
import { SharedLogTableComponent } from '@app/shared/components/log-table/shared-log-table.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { FilterPhotoReportComponent } from './components/filter-photo-report/filter-photo-report.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ImageCropperComponentTR } from './components/image-cropper/image-cropper.component';

import { RouterModule } from '@angular/router';
import { PhonePipe } from '@app/core/pipes/phone.pipe';
import { FilenameCropDirective, GenComponentDirective } from '@app/core/directives';
import { WorkingDaysComponent } from './components/working-days/working-days.component';
import { ShortFioPipe } from '@app/core/pipes/short-fio.pipe';
import { SlimScrollDirective } from '@app/core/directives/slim-scroll.directive';
import { TimeTextPipe } from '@app/core/pipes/time-text.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconazerComponent } from './components/iconazer/iconazer.component';
import { DropDownWithTagsComponent } from './components/drop-down-with-tags/drop-down-with-tags.component';
import { StoreDraggableComponent } from './components/store-draggable/store-draggable.component';
import { TranslateModule } from '@ngx-translate/core';
import { RemoveCommaPipe } from '@app/core/pipes/remove-comma.pipe';
import { StringToDatePipe } from '@app/core/pipes/string-to-date.pipe';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DatePickerIntervalComponent } from './components/date-picker-interval/date-picker-interval.component';
import { PaginationComponent } from './components/pagination/pagination.component';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        DxVectorMapModule,
        DxBarGaugeModule,
        DxListModule,
        DxDropDownBoxModule,
        DxAccordionModule,
        DxButtonModule,
        DxChartModule,
        DxTextBoxModule,
        DxTabPanelModule,
        DxPieChartModule,
        DxDataGridModule,
        DxPopupModule,
        DxTreeListModule,
        DxNumberBoxModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxCheckBoxModule,
        DxFormModule,
        DxTagBoxModule,
        DxLookupModule,
        DxLoadIndicatorModule,
        DxValidatorModule,
        DxValidationSummaryModule,
        DxValidationGroupModule,
        NgxLoadingModule,
        ToastrModule,
        DxTabsModule,
        DxLoadPanelModule,
        RouterModule,
        DxSwitchModule,
        DxCalendarModule,
        DxScrollViewModule,
        TranslateModule,
        DxSliderModule,
        DxDateRangeBoxModule
    ],
    exports: [
        CommonModule,
        FontAwesomeModule,
        DxVectorMapModule,
        DxBarGaugeModule,
        DxListModule,
        DxDropDownBoxModule,
        DxAccordionModule,
        DxButtonModule,
        DxChartModule,
        DxTextBoxModule,
        DxTabPanelModule,
        DxPieChartModule,
        DxDataGridModule,
        DxPopupModule,
        DxTreeListModule,
        DxNumberBoxModule,
        DxFileUploaderModule,
        SharedLogTableComponent,
        SharedGetFilesComponent,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxCheckBoxModule,
        DxFormModule,
        DxLoadIndicatorModule,
        DxTagBoxModule,
        DxLookupModule,
        DxValidatorModule,
        DxValidationSummaryModule,
        DxValidationGroupModule,
        SafePipe,
        PhonePipe,
        RemoveCommaPipe,
        ShortFioPipe,
        TimeTextPipe,
        NgxLoadingModule,
        ToastrModule,
        DxTabsModule,
        DxLoadPanelModule,
        FilterPhotoReportComponent,
        DxSwitchModule,
        GenComponentDirective,
        SlimScrollDirective,
        WorkingDaysComponent,
        WorkingDaysComponent,
        DxCalendarModule,
        GenComponentDirective,
        IconazerComponent,
        DropDownWithTagsComponent,
        StoreDraggableComponent,
        DxScrollViewModule,
        FilenameCropDirective,
        TranslateModule,
        DxSliderModule,
        StringToDatePipe,
        SwitcherComponent,
        DatePickerComponent,
        DatePickerIntervalComponent,
        DxDateRangeBoxModule,
        PaginationComponent
    ],
    declarations: [
        SharedLogTableComponent,
        SharedGetFilesComponent,
        SafePipe,
        PhonePipe,
        StringToDatePipe,
        RemoveCommaPipe,
        ShortFioPipe,
        TimeTextPipe,
        FilterPhotoReportComponent,
        ConfirmDialogComponent,
        ImageCropperComponentTR,
        GenComponentDirective,
        SlimScrollDirective,
        WorkingDaysComponent,
        WorkingDaysComponent,
        IconazerComponent,
        DropDownWithTagsComponent,
        StoreDraggableComponent,
        FilenameCropDirective,
        SwitcherComponent,
        DatePickerComponent,
        DatePickerIntervalComponent,
        PaginationComponent
    ]
})
export class DxSharedModule {}
