import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { HeaderComponent } from '@app/layouts/header/header.component';
import { BodyComponent } from '@app/layouts/body/body.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { AuthLayoutComponent } from '@app/layouts/auth-layout/auth-layout.component';
import { AppComponent } from '@app/app.component';
import { NavComponent } from '@app/layouts/nav/nav.component';
import { DragulaModule } from 'ng2-dragula';
import { CoreModule } from '@app/core/core.module';
import { DxSharedModule } from '@app/shared/dx-shared.module';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MetrikaModule } from 'ng-yandex-metrika';
import { environment } from '@env/environment';
import 'hammerjs';
import 'mousetrap';

const ruMessages = require('devextreme/localization/messages/ru.json');
// Set locale according the browser language
import { locale, loadMessages } from "devextreme/localization";
import './core/services/declarations.service';
import { ToastrModule } from 'ngx-toastr';
import {BreadcrumbService } from 'xng-breadcrumb';
import {BreadcrumbModule } from 'params-xng-breadcrumb';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { BrowserTransferStateModule } from '@angular/platform-browser';


import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UserResolver } from '@app/core/guards/user.resolver';
import {
  faAlignJustify,
  
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faBars,
  faBomb,
  faBook,
  faBus,
  faCar,
  faCaretDown,
  faCaretUp,
  faCarSide,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckDouble,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCog,
  faCoins,
  faCopyright,
  faPlusSquare,
  faMinusCircle,
  // faCalendar,
  faCalendarDay,
  faCross,
  faDoorOpen,
  faDownload,
  faDraftingCompass,
  faDrawPolygon,
  faEdit,
  faEraser,
  faExchangeAlt,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFile,
  faFileCsv,
  faFileDownload,
  faFilter,
  faFolderOpen,
  faGlobe,
  faGlobeAmericas,
  faGripVertical,
  faHome,
  faHourglassStart,
  faImage,
  faImages,
  faInfo,
  faInfoCircle,
  faKey,
  faLanguage,
  faLightbulb,
  faLongArrowAltRight,
  faDotCircle,
  faMagic,
  faMapMarkerAlt,
  faMinus,
  faPaintBrush,
  faPencilAlt,
  faPlayCircle,
  faPlus,
  faPuzzlePiece,
  faReceipt,
  faRedo,
  faRoute,
  faSave,
  faSpinner,
  faSquare,
  faStoreAlt,
  faStream,
  faSyncAlt,
  faTasks,
  faThList,
  faTimes,
  faTrash,
  faUndo,
  faUser,
  faUserCircle,
  faUserClock,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUserSecret,
  faWalking,
  faWindowMinimize,
  faUpload,
  faSquareCaretRight,
  faCaretRight,

} from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck, faClock, faComments,  
   faCalendar , faEye, faFileAlt, faFilePdf, faIdCard, faPaperPlane, faWindowMaximize } from '@fortawesome/free-regular-svg-icons';
import { PermissionsResolver } from '@app/core/guards';
import { YamapngModule } from 'projects/yamapng/src/public_api';
import { YaCoreModule } from 'projects/yamapng/src/lib/core.module';
import { GalleryModule } from 'projects/ks89/angular-modal-gallery/src/public_api';
import { whitelistedDomains } from '@app/core/const';
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCheckCircle as faCheckCircleReg, } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';

import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LocalStorageService, provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from 'ngx-webstorage';




export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
registerLocaleData(localeRu, 'ru');


@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [AppComponent, HeaderComponent, NavComponent, BodyComponent, AuthLayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BreadcrumbModule,
    DragulaModule,
    BrowserAnimationsModule,
    CoreModule,
    DxSharedModule,
    GalleryModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right'
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.chasingDots,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#7b7782',
      secondaryColour: '#b57a7c',
      tertiaryColour: '#6d2d28'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return JSON.parse( localStorage.getItem('ngx-webstorage:access_token') ||"null")   //localStorage.getItem('access_token');
        },
        allowedDomains: whitelistedDomains,
        // disallowedRoutes: ["https://js.devexpress.com/"],
        authScheme: ''
      }
    }),
    FontAwesomeModule,
    MetrikaModule.forRoot(
      { id: 51418249, webvisor: environment.production } // CounterConfig | CounterConfig[]
    ),
    YamapngModule,
    YaCoreModule.forRoot({
      apiKey: '6dc6eebd-4766-439e-af89-6be9736df6f4'
    }),
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-bottom-right',
      progressBar: true
    }),    
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxSkeletonLoaderModule
    //NgxWebstorageModule,
  ],
  providers: [
    UserResolver, 
    PermissionsResolver,
    { provide: LOCALE_ID, useValue: 'ru' },   	
    provideNgxWebstorage(
		withNgxWebstorageConfig({ separator: ':', caseSensitive: true }),
		withLocalStorage(),
		withSessionStorage(),

	),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faPaperPlane,
      faUndo,
      faRedo,
      faAngleDoubleLeft,
      faAngleDoubleRight,
      faAngleDoubleDown,
      faAngleDoubleUp,
      faAngleDown,
      faAngleLeft,
      faAngleRight,
      faPlusSquare,
      faMinusCircle,
      faAngleUp,
      faPencilAlt,
      faBars,
      faUserSecret,
      faArrowUp,
      faArrowDown,
      faSpinner,
      faDoorOpen,
      faInfo,
      faInfoCircle,
      faChevronDown,
      faChevronUp,
      faChevronRight,
      faChevronLeft,
      faCircle,
      faCalendar,
      faCalendarDay,
      faTrash,
      faEye,
      faHourglassStart,
      faUserFriends,
      faFileCsv,
      faFileDownload,
      faDownload,
      faExchangeAlt,
      faFile,
      faFileAlt,
      faSave,
      faSyncAlt,
      faIdCard,
      faUserEdit,
      faStoreAlt,
      faCross,
      faMagic,
      faGlobeAmericas,
      faCoins,
      faUser,
      faUserClock,
      faTimes,
      faCar,
      faClock,
      faLongArrowAltRight,
      faWindowMaximize,
      faWindowMinimize,
      faDrawPolygon,
      faGlobe,
      faDraftingCompass,
      faCloudDownloadAlt,
      faArrowRight,
      faCopyright,
      faBomb,
      faExclamationTriangle,
      faCheckDouble,
      faCheckCircleReg,
      faEdit,
      faHome,
      faChartPie,
      faThList,
      faUserCircle,
      faRoute,
      faMapMarkerAlt,
      faPuzzlePiece,
      faPlayCircle,
      faPlus,
      faUserPlus,
      faQrcode,
      faTimes,
      faCaretUp,
      faCaretDown,
      faChartLine,
      faChartBar,
      faCalendarCheck,
      faFilter,
      faTasks,
      faCheck,
      faSquare,
      faLanguage,
      faPaintBrush,
      faLightbulb,
      faWindowMaximize,
      faStream,
      faBook,
      faMinus,
      faEraser,
      faCog,
      faGripVertical,
      faKey,
      faFolderOpen,
      faImages,
      faImage,
      faComments,
      faExternalLinkAlt,
      faAlignJustify,
      faCarSide,
      faWalking,
      faBus,
      faCheckCircle,
      faTimesCircle,
      faQuestionCircle,
      faReceipt,
      faCloudUploadAlt,
      faFilePdf,
      faUpload,
      faFilePdf,
      faCaretRight, 
      faCaretDown
      
    );
    loadMessages(ruMessages);
    locale(navigator.language);
  }

}
