Date.prototype.convertDateToServerString = convertDateToServerString;
Date.prototype.convertDateToServerStringPoint= convertDateToServerStringPoint
Date.prototype.convertDateToServerStringDash= convertDateToServerStringDash
Date.prototype.convertDateToTimeString = convertDateToTimeString;
Date.prototype.convertDateToServerStringNew = convertDateToServerStringNew;

String.prototype.convertServerStringToDate = convertServerStringToDate;
String.prototype.convertTimeStringToDate = convertTimeStringToDate;
String.prototype.convertServerStringToDatePoint = convertServerStringToDatePoint;
String.prototype.convertServerStringToDateDash = convertServerStringToDateDash;
String.prototype.upperFirstChar = upperFirstChar;
String.prototype.lowFirstChar = lowFirstChar;
Array.prototype.findElementInArray = findElementInArray;

interface Date {
  convertDateToServerString: typeof convertDateToServerString;
  convertDateToTimeString: typeof convertDateToTimeString;
  convertDateToServerStringPoint: typeof convertDateToServerStringPoint;
  convertDateToServerStringDash: typeof convertDateToServerStringDash;
  convertDateToServerStringNew: typeof convertDateToServerStringNew
}

interface String {
  convertServerStringToDate: typeof convertServerStringToDate;
  convertTimeStringToDate: typeof convertTimeStringToDate;
  convertServerStringToDatePoint:typeof convertServerStringToDatePoint;
  convertServerStringToDateDash:typeof convertServerStringToDateDash;
  upperFirstChar: typeof upperFirstChar;
  lowFirstChar: typeof lowFirstChar;
}

interface Array<T> {
  findElementInArray(searchElement: string | number, dataKey: string, returnIndex: boolean): Array<T> | number;
}

function convertDateToServerString(): number {
  const dd = this.getDate() < 10 ? '0' + this.getDate() : this.getDate();
  const mm = this.getMonth() + 1 < 10 ? '0' + (this.getMonth() + 1) : this.getMonth() + 1;
  const yyyy = this.getFullYear();
  return +('' + yyyy + mm + dd);
}
function convertDateToServerStringNew(): string {
  const dd = this.getDate() < 10 ? '0' + ("" + this.getDate()) : this.getDate();
  const mm = this.getMonth() + 1 < 10 ? '0' + (this.getMonth() + 1) : this.getMonth() + 1;
  const yyyy = this.getFullYear();
  return ('' + dd  + mm + yyyy );
}

function convertDateToServerStringPoint(): string {
  const dd = this.getDate() < 10 ? '0' + this.getDate() : this.getDate();
  const mm = this.getMonth() + 1 < 10 ? '0' + (this.getMonth() + 1) : this.getMonth() + 1;
  const yyyy = this.getFullYear();
  return `${dd}.${mm}.${yyyy}`;
}

function convertDateToServerStringDash(): string {
  const dd = this.getDate() < 10 ? '0' + this.getDate() : this.getDate();
  const mm = this.getMonth() + 1 < 10 ? '0' + (this.getMonth() + 1) : this.getMonth() + 1;
  const yyyy = this.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

function convertServerStringToDate(): Date {
  const yyyy = +this.substr(0, 4);
  const mm = +this.substr(4, 2);
  const dd = +this.substr(6, 2);
  return new Date(yyyy, mm - 1, dd);
}

function convertServerStringToDatePoint(): Date {
  const dd = this.substr(0, 2);
  const mm = this.substr(3, 2);
  const yyyy = this.substr(6, 4);
  return new Date(yyyy, mm - 1, dd);
}

function convertServerStringToDateDash(): Date {
  const yyyy = +this.substr(0, 4);
  const mm = +this.substr(5, 2);
  const dd = +this.substr(8, 2);
  return new Date(yyyy, mm - 1, dd);
}


function convertDateToTimeString(): string {
  if (this === null) return null;

  return ('0' + this.getHours()).slice(-2) + ':' + ('0' + this.getMinutes()).slice(-2);
}

function upperFirstChar(): string {
  if (!this) return this;

  return this[0].toUpperCase() + this.slice(1);
}

function lowFirstChar(): string {
  if (!this) return this;

  return this[0].toLowerCase() + this.slice(1);
}

function convertTimeStringToDate(): Date {
  if (!this || this.toString().length === 0) {
    return null;
  }

  const [hour, min, sec] = this.split(':');

  switch (this.match(/:/g).length) {
    case 1: {
      return new Date(0, 0, 0, +hour, +min);
    }
    case 2: {
      return new Date(0, 0, 0, +hour, +min, +sec);
    }
    default: {
      return new Date(0);
    }
  }
}

/**
 * Поиск элемента в массиве. Возвращаем найденный элемент или его индекс.
 * @param {string | number} searchElement (искомый элемент)
 * @param {string} dataKey (по какому полю в массиве ищем)
 * @param {boolean} returnIndex (если true - вернуть индекс массива, если false - вернуть значение)
 * @returns {any[] | number} (массив или число)
 */
function findElementInArray(searchElement: string | number, dataKey?: string, returnIndex = false): any[] | number {
  for (let i = 0; i < this.length; i++) {
    if (dataKey) {
      if (this[i][dataKey] === searchElement) {
        return returnIndex ? i : this[i];
      }
    } else {
      if (this[i] === searchElement) {
        return returnIndex ? i : this[i];
      }
    }
  }
  return null;
}
