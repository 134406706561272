import { Routes } from '@angular/router';
import { CanActivateTeam } from '@app/core/guards';

export const CONTENT_ROUTES: Routes = [
  {
    path: 'home',
    loadChildren: () => import('@app/modules/home/home.module').then(m => m.HomeModule),
    data: {
      breadcrumb: {
        skip: true
      }
    }
  },
  {
    path: 'routes',
    loadChildren: () => import('@app/modules/routes/routes.module').then(m => m.RoutesModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_ROUTES_DISPLAY',
      breadcrumb: {
        skip: true
      }
    }
  },
  {
    path: 'logistic',
    loadChildren: () => import('@app/modules/logistic/logistic.module').then(m => m.LogisticModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_LOGISTIC_DISPLAY',
      breadcrumb: {
        skip: true
      }
    }
  },
  {
    path: 'matrix',
    loadChildren: () => import('@app/modules/matrix/matrix.module').then(m => m.MatrixModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_CL_DISPLAY',
      breadcrumb: {
        label: 'breadcrumbs.matrix.MATRIX'
      }
    }
  },
  {
    path: 'employees',
    loadChildren: () => import('@app/modules/employees/employees.module').then(m => m.EmployeesModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_EMPLOYEES_DISPLAY',
      breadcrumb: {
        label: 'breadcrumbs.employees.EMPLOYEES'
      }
    }
  },
  {
    path: 'status-mch',
    loadChildren: () => import('@app/modules/status-mch/status-mch.module').then(m => m.StatusMchModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_STATUS-MERCH_DISPLAY',
      breadcrumb: {
        label: 'breadcrumbs.status-mch.STATUS_MCH'
      }
    }
  },
  {
    path: 'constructor',
    loadChildren: () => import('@app/modules/constructor-planogramm/constructor-planogramm.module').then(m => m.ConstructorPlanogrammModule),
    resolve: { user: CanActivateTeam },
    data: {
      breadcrumb: {
        skip: true
      },
      permission: '-'
    }
  },
  {
    path: 'stores',
    loadChildren: () => import('@app/modules/stores/stores.module').then(m => m.StoresModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_STORES_DISPLAY'
    }
  },
  {
    path: 'bi-reports',
    loadChildren: () => import('@app/modules/bi-reports/bi-reports.module').then(m => m.BiReportsModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: '-',
      breadcrumb: {
        skip: true
      }
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('@app/modules/reports/reports.module').then(m => m.ReportsModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_REPORTS_DISPLAY',
      breadcrumb: {
        skip: true
      }
    }
  },
  {
    path: 'visits',
    loadChildren: () => import('@app/modules/visits/visits.module').then(m => m.VisitsModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_VISITS_DISPLAY',
      breadcrumb: {
        label: 'breadcrumbs.visits.VISITS'
      }
    }
  },
  {
    path: 'mass-visits',
    loadChildren: () => import('@app/modules/mass-vissit/mass-vissit.module').then(m => m.MassVissitModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_MASSIVE_CHANGES_DISPLAY',
      breadcrumb: {
        label: 'breadcrumbs.mass-visits.MASSVISITS'
      }
    }
  },
  {
    path: 'crowdsourcing',
    loadChildren: () => import('@app/modules/crowdsourcing/crowdsourcing.module').then(m => m.CrowdsourcingModule),
    resolve: { user: CanActivateTeam },
    data: {
      permission: 'TAB_CROWDSOUNCING_DISPLAY',
      breadcrumb: {
        label: 'breadcrumbs.crowdsourcing.CROWDSOUNCING'
      }
    }
  },
  {
    path: 'administration',
    loadChildren: () => import('@app/modules/admin/admin.module').then(m => m.AdminModule),
    resolve: { user: CanActivateTeam },
    data: {
      breadcrumb: {
        label: 'breadcrumbs.administrations.ADMINISTRATIONS'
      },
      permission: 'TAB_ADMINISTRATIONS_DISPLAY'
    }
  },
  {
    path: 'other',
    loadChildren: () => import('@app/modules/other/other.module').then(m => m.OtherModule),
    resolve: { user: CanActivateTeam },
    data: {
      breadcrumb: {
        skip: true
      }
    }
  },
  {
    path: 'filehosting',
    loadChildren: () => import('@app/modules/filehosting/filehosting.module').then(m => m.FilehostingModule),
    resolve: { user: CanActivateTeam },
    data: {
      breadcrumb: {
        skip: true
      },
      permission: 'TAB_FILEHOSTING_DISPLAY'
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('@app/modules/settings/settings.module').then(m => m.SettingsModule),
    resolve: { user: CanActivateTeam },
    data: {
      breadcrumb: {
        skip: true
      }
    }
  },  {
    path: 'posm',
    loadChildren: () => import('@app/modules/posm/posm.module').then(m => m.PosmModule),
    resolve: { user: CanActivateTeam },
    data: {
      breadcrumb: {
        // label: 'breadcrumbs.administrations.ADMINISTRATIONS'
      },
      permission: 'TAB_POSM_DISPLAY'
    }
  },
];
